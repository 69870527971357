// Fonts
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import "./assets/fonts/stylesheet.css";

// HTML variables
:root {
  --font-family-base: "Merriweather", serif;
  --font-family-caption: "Decimal", Arial, Helvetica, sans-serif;
  --color-primary: #fa582d;
  --color-text: #5f5f5f;
  --color-dark: #141414;
  --color-gray: #d8d8d8;
  --color-light: #f4f4f2;
  --color-blue: #00bfe7;
}

// Custom colors
$blue: #0784e9;
// $green: #2cb43a;
$body-color: var(--color-text);
$bullseye-red-color: #c50518 !default;
$bullseye-danger: #c50518;
$bullseye-success: #0da108;
$bullseye-warning: #ffc107;
$grid-gutter-width: 10px !default;

// Bootstrap
// Bootstrap - required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$font-family-base: var(--font-family-base);
$font-family-caption: var(--font-family-caption);
$enable-responsive-font-sizes: true;
$font-size-base: 1rem;
$body-bg: var(--color-light);
$theme-colors: (
  "primary": #fa582d,
  "dark": #141414,
  "gray": #d8d8d8,
  "success": #00cc66
);
$grid-columns: 24;

@include media-breakpoint-down(md) {
  .bl-no-gutter-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@include media-breakpoint-down(md) {
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Buttons
$btn-border-radius: 1.25rem;
$btn-font-family: $font-family-caption;
$btn-padding-x: 1.25em;
$btn-transition: none;
@each $name, $color in $theme-colors {
  .btn-#{$name}:not(:disabled):not(.disabled):hover {
    border-color: lighten($color, 7%) !important;
    background-color: lighten($color, 7%) !important;
  }
}
.btn-primary {
  color: var(--color-dark) !important;
}

// Links
$link-color: var(--color-primary);
$link-hover-color: #f93a08;
$link-decoration: underline;

// Forms
$input-font-family: Arial, helvetica, sans-serif;
$input-border-color: var(--color-gray);

label,
.form-label {
  font: 600 0.8em/1em $font-family-caption;
  color: var(--color-dark);
}

// Selects
  select {
    padding-right: 1.5rem !important;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="%23495057" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>') no-repeat 98% 50%;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
  }

// Checkboxes (fix in Safari)
input[type=checkbox] {
  min-width: 13px;
  max-width: 13px;
  min-height: 13px;
  max-height: 13px;
}