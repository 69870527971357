.class-mobile {
  .name {
    font: 600 1.2em/1.3em var(--font-family-caption);
  }

  .atp {
    color: var(--color-dark);
    font-family: var(--font-family-caption);
    font-size: 1.1em;
  }

  .basic-details {
    color: #949494;
    font-family: var(--font-family-caption);
    text-transform: uppercase;
    font-size: 0.9em;
  }

  .label {
    float: left;
    color: var(--color-dark);
    max-width: 6em;
  }

  .value {
    display: block;
    margin-left: 7em;
  }
}