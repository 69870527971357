.class-list-desktop {
  table {
    background: #fff;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1199.98px)  {
    font-size: 0.9em;
  }

  thead tr,
  tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--color-gray);
  }

  th, td {
    padding: 1em 0.5em;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    font: 600 0.9em/1.2em var(--font-family-caption);
    color: var(--color-dark);
  }
}