@import "react-datepicker/dist/react-datepicker.css";

.class-filters {
  .inputs {
    align-items: flex-end;
  }

  .form-group {
    margin-bottom: 1.5em;
  }

  label {
    display: block;
  }

  input[type=text], select, .btn {
    width: 100%;
  }

  .form-group-name {
    margin-bottom: 0.5em;
    border-bottom: 1px solid var(--color-gray);
    padding-bottom: 0.5em;
    font-family: var(--font-family-caption);
    font-size: 0.8rem;
  }

  .form-check-inline {
    align-items: flex-start;
  }

  .form-check-label {
    font-family: var(--font-family-base);
    line-height: 1.4;
    color: var(--color-text);
  }

  .form-date {
    padding-right: 2em;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 361.77 361.77' version='1.1' viewBox='0 0 361.77 361.77' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m323.88 43.77h-27.5v-18.77c0-13.807-11.193-25-25-25h-1c-13.807 0-25 11.193-25 25v18.77h-129v-18.77c0-13.807-11.193-25-25-25h-1c-13.807 0-25 11.193-25 25v18.77h-27.5c-13.807 0-25 11.193-25 25v268c0 13.809 11.193 25 25 25h286c13.807 0 25-11.191 25-25v-268c0-13.807-11.194-25-25-25zm-17 278.5h-252v-203h252v203z'/%3E%3Cpath d='m89.136 211.13h43.498c2.209 0 4-1.791 4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209 0-4 1.791-4 4v43.498c0 2.209 1.791 4 4 4z'/%3E%3Cpath d='m159.14 211.13h43.498c2.209 0 4-1.791 4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209 0-4 1.791-4 4v43.498c0 2.209 1.791 4 4 4z'/%3E%3Cpath d='m229.14 211.13h43.498c2.209 0 4-1.791 4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209 0-4 1.791-4 4v43.498c0 2.209 1.791 4 4 4z'/%3E%3Cpath d='m89.136 281.13h43.498c2.209 0 4-1.791 4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209 0-4 1.791-4 4v43.498c0 2.209 1.791 4 4 4z'/%3E%3Cpath d='m159.14 281.13h43.498c2.209 0 4-1.791 4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209 0-4 1.791-4 4v43.498c0 2.209 1.791 4 4 4z'/%3E%3Cpath d='m229.14 281.13h43.498c2.209 0 4-1.791 4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209 0-4 1.791-4 4v43.498c0 2.209 1.791 4 4 4z'/%3E%3C/svg%3E%0A") no-repeat center right 0.5em;
    background-size: 1.1em;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: var(--color-primary);
      box-shadow: 0 0 0 1px var(--color-primary);
    }
  }

  .react-datepicker {
    border-color: var(--color-gray);
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    
    .react-datepicker__triangle {
      &::before {
        border-bottom-color: var(--color-gray);
      }

      &::after {
        border-bottom-color: var(--color-light);
      }
    }

    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        top: 5px;
      }
    }

    .react-datepicker__header {
      background-color: var(--color-light);
      border-bottom-color: var(--color-gray);

      .react-datepicker__current-month {
        padding-top: 0.2em;
        font: 600 1em/1.4em var(--font-family-caption);
      }
    }

    .react-datepicker__day--keyboard-selected {
      background-color: var(--color-dark);
    }
    
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background-color: #fa582d;

      &:hover {
        background-color: #fa582d;
      }
    }
  }

  .guaranteed {
    .form-label { 
      margin-bottom: 0.5rem; 
    }
    .form-check-inline {
      align-items: center;
    }
  }

  .course-duration {
    white-space: nowrap;
    color: #999;
  }

  .field-atp {
    input {
      background-position: -100px -100px !important;
    }
  }
}

.react-datepicker__close-icon {
  height: auto;
  padding: 0.3em;
  background-color: #fff;
  top: 50%;
  right: 0.3em;
  transform: translateY(-50%);

  &::after {
    padding: 0;
    background-color: #fff;
    color: #141414;
    font-size: 24px;
  }
}